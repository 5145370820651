import * as React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

// Components
import Layout from "../components/layout"
import Hero from "../components/hero"
import { Seo } from "../components/seo"

// Loadable Components
const Feature = loadable(() => import("../components/feature"))
const Highlight = loadable(() => import("../components/highlight"))
const Interlude = loadable(() => import("../components/interlude"))

const IndexPage = ({ data }) => {
  const home = data.contentfulPage

  return (
    <Layout>
      <Hero
        type={home.pageType}
        title={home.pageTitle.split("\\n")}
        description={home.pageDescription.pageDescription}
        image={home.heroImage}
      />

      {data.contentfulPage.pageContent.map((content, i) => {
        if (content.internal.type === "ContentfulFeature") {
          return (
            <Feature
              key={i}
              name={content.subtitle}
              title={content.title}
              description={content.description}
              image={content.image}
              inverted={content.isReverse}
            />
          )
        } else if (content.internal.type === "ContentfulHighlight") {
          return (
            <Highlight
              key={i}
              title={content.title}
              caption={content.content}
              cta={content.cta}
              image={content.image}
              location={content.location}
              link={content.link}
              isEven={content.isEven}
              isReverse={content.isReverse}
            />
          )
        } else if (content.internal.type === "ContentfulInterlude") {
          return (
            <Interlude
              key={i}
              description={content.description.description}
              images={content.images}
              layout={content.layout}
            />
          )
        } else {
          return null
        }
      })}
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(pageName: { eq: "Homepage" }) {
      pageType
      pageTitle
      pageDescription {
        pageDescription
      }
      heroImage {
        title
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 50)
      }
      pageContent {
        ... on ContentfulFeature {
          internal {
            type
          }
          title
          subtitle
          image {
            file {
              url
            }
          }
          description {
            raw
          }
          isReverse
        }

        ... on ContentfulHighlight {
          internal {
            type
          }
          title
          cta
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                gatsbyImageData(height: 300)
              }
            }
          }
          image {
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          location {
            lat
            lon
          }
          link
          isEven
          isReverse
        }

        ... on ContentfulInterlude {
          internal {
            type
          }
          description {
            description
          }
          images {
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          layout
        }
      }
    }
  }
`

export default IndexPage

export const Head = () => <Seo />
